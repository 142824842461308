<template>
    <div class="page-bottom-box">
        <div class="left-url-box">
            <div class="url-list">
                <div style="font-size: 15px; line-height: 45px;">{{ setting.name }}</div>
                <div style="font-size: 15px; line-height: 45px;">地址：{{ setting.address }}</div>
                <div style="font-size: 15px; line-height: 45px;">邮编：{{ setting.postcode }}  电话：{{ setting.phone }}</div>
                <div style="font-size: 15px; line-height: 45px;">传真：{{ setting.fax }}</div>
            </div>
        </div>
        <div class="line-box">
            <div class="bottom-logo">
                <img src="../assets/images/康养logo圆.png" style="width: 100%; height: 100%; border-radius: 100%;" />
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    data() {
        return {
            setting: {
                name: '',
                address: '',
                postcode: '',
                phone: '',
                fax: '',
            },
        }
    },
    created() {
        this.getSetting()
    },
    methods: {
        // 获取底部设置
        getSetting() {
        api.settingList().then(res => {
            if(res) {
            this.setting = res
            }
        })
        },
    }
}

</script>

<style lang="less">
.page-bottom-box {
    width: 100%;
    height: 300px;
    margin-top: 50px;
    display: flex;
    background: #46596A;

    .line-box {
        width: 45%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .bottom-logo{
            width: 203px;
            height: 203px;
            border-radius: 263px;
        }
    }

    .left-url-box{
        width: 55%;
        height: 100%;
        display: flex;
        flex-direction: column;
        color: #ffffff;
        padding-left: 180px;

        .url-list {
            text-align: left;
            padding-top: 50px;
            padding-left: 100px;
        }
    }
}

</style>