import axios from "./axios.js"
// 通用api，包括登录等
const apiList = {
  // 获取导航栏
  navList: '/v1/navigation',
  // 获取轮播图
  bannerList: '/v1/slideshow',
  // 获取新闻
  newsList: '/v1/pressCenter',
  // 获取新闻详情
  newsInfo: '/v1/pressCenter',
  // 科普知识
  docList: '/v1/impartKnowledge',
  // 活动列表
  activeList: '/v1/impartActivity',
  // 获取活动详情
  activityInfo: '/v1/impartActivity',
  // 活动报名
  actApplySave: '/v1/activityApplyInfo',
  // 获取友情链接
  linkClassList: '/v1/linkClass',
  // 获取设置
  settingList: '/v1/siteBottomSetting',
  // 获取标签列表
  libelList: '/v1/label',
  // 标签获取数据
  knowByLabelId: '/v1/labelRelevance',
  // 科学馆内容
  lifeScience: '/v1/lifeScienceHall',
  // 展览回顾内容
  backInfoList: '/v1/expoLookBack',
  // 会展预约
  reserveInfoSave: '/v1/reserveInfo',
  // 获取团队列表
  teamInfoList: '/v1/serveTeam',
  // 申请入驻
  teamInfoSave: '/v1/serveTeam',
  // 服务风采
  teamMienList: '/v1/teamMien',
  // 获取展览标签
  lookLabelList: '/v1/expoLookBackLabel',

}


export default {
  apiList,

  navList (data = {}) {
    return axios({
      url: apiList.navList,
      method: 'get',
      data
    })
  },
  bannerList (data = {}) {
    return axios({
      url: apiList.bannerList,
      method: 'get',
      data
    })
  },
  newsList (data = {}) {
    return axios({
      url: apiList.newsList,
      method: 'get',
      data
    })
  },
  newsInfo (id) {
    return axios({
      url: apiList.newsList + '/' + id,
      method: 'get',
    })
  },
  docList (data = {}) {
    return axios({
      url: apiList.docList,
      method: 'get',
      data
    })
  },
  activeList (data = {}) {
    return axios({
      url: apiList.activeList,
      method: 'get',
      data
    })
  },
  activityInfo (id) {
    return axios({
      url: apiList.activityInfo + '/' + id,
      method: 'get',
    })
  },
  actApplySave (data = {}) {
    return axios({
      url: apiList.actApplySave,
      method: 'post',
      data
    })
  },
  linkClassList (data = {}) {
    return axios({
      url: apiList.linkClassList,
      method: 'get',
      data
    })
  },
  settingList (data = {}) {
    return axios({
      url: apiList.settingList,
      method: 'get',
      data
    })
  },
  libelList (data = {}) {
    return axios({
      url: apiList.libelList,
      method: 'get',
      data
    })
  },
  knowByLabelId (data = {}) {
    return axios({
      url: apiList.knowByLabelId,
      method: 'get',
      data
    })
  },
  lifeScience (data = {}) {
    return axios({
      url: apiList.lifeScience,
      method: 'get',
      data
    })
  },
  backInfoList (data = {}) {
    return axios({
      url: apiList.backInfoList,
      method: 'get',
      data
    })
  },
  reserveInfoSave (data = {}) {
    return axios({
      url: apiList.reserveInfoSave,
      method: 'post',
      data
    })
  },
  teamInfoList (data = {}) {
    return axios({
      url: apiList.teamInfoList,
      method: 'get',
      data
    })
  },
  teamInfoSave (data = {}) {
    return axios({
      url: apiList.teamInfoSave,
      method: 'post',
      data
    })
  },
  teamMienList (data = {}) {
    return axios({
      url: apiList.teamMienList,
      method: 'get',
      data
    })
  },
  lookLabelList (data = {}) {
    return axios({
      url: apiList.lookLabelList,
      method: 'get',
      data
    })
  },
  
}
