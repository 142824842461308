<template>
    <div class="title-box">
        <!-- 导航栏 -->
        <div class="logo-box">
            <div class="logo">
                <img src="../assets/images/logo.png" style="width: 280px; height: 130px;"/>
            </div>
            <div class="query">
                <input class="query-input" placeholder="请输入关键词"/>
                <el-icon style="font-size: 25px; font-weight: bold; color: #0AA440;"><Search /></el-icon>
            </div>
        </div>
        <!-- 路由 -->
        <div class="url-box">
            <div v-for="(item,index) in navList" :style="{marginLeft: index == 0?'5%':''}" :key="index" class="nav-name" @mouseleave="handleMouseOver(index)" @mouseenter="onMouseOut(index)">
                <div class="nav-name-box"  :class="{ 'show-back': item.show }" @click="skipPage(item)">
                    <div>{{ item.name }}</div>
                    <el-icon v-if="item.children.length>0" class="children-icon"><CaretBottom /></el-icon>
                </div>
                <div v-if="item.show" class="hide-nav" >
                    <div class="child-box" v-for="(val, idx) in item.children" :key="idx" @click="skipPage(val)">
                        {{ val.name }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 轮播图 -->
        <!-- <div class="banner-box">
            <el-carousel height="100%" autoplay style="width: 100%; height: 100%;" :pause-on-hover="false"
                indicator-position="none">
                <el-carousel-item style="height: 667px" v-for="(item, index) in imgList" :key="index">
                <img :src="item.imgUrl" style="width: 100%; height: 100%;" />
                </el-carousel-item>
            </el-carousel>
        </div> -->
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    data() {
        return {
            navList: [],
            imgList: [],
        }
    },
    created() {
        this.getNavList()
        // this.getBannerImgs()
    },
    methods: {
        // 获取导航栏
        getNavList() {
            api.navList({ status: 1, viewState: 0, rootIn: 1 }).then(res => {
                if (res) {
                    this.navList = res
                }
            })
        },
        // 获取轮播图
        getBannerImgs() {
            api.bannerList({ status: 0 }).then(res => {
                if (res) {
                this.imgList = res
                }
            })
        },
        // 控制显示隐藏
        handleMouseOver(index) {
            this.navList[index].show = false
        },
        onMouseOut(index) {
            this.navList[index].show = true
        },
        // 导航点击跳转
        skipPage(val) {
            if (val.children.length == 0) {
                this.$router.push(val.pageUrl)
            }
        },
    }
}
</script>

<style lang="less">
.title-box {
    width: 100%;
    height: 200px;
    
    .logo-box {
        width: 100%;
        height: 130px;
        display: flex;
        justify-content: space-around;
        z-index: 9;

        .logo {
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .query{
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            
            .query-input {
                width: 250px;
                border: 2px solid #16B82E;
                border-radius: 5px;
                height: 25px;
                margin-right: 15px;
            }
        }
    }

    .url-box {
        width: 100%;
        background: #16B82E;
        height: calc(100% - 140px);
        display: flex;
        flex-direction: row; 
        justify-content: center;
        align-items: center;
        color: #ffffff;
        z-index: 99;

        .nav-name {
            width: auto;
            height: 100%;
            font-size: 20px;
            font-weight: bold;
            padding: 0 18px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            position: relative;
            cursor:pointer;

            .show-back{
                background: #09A626 !important;
            }

            .children-icon {
                font-size: 20px;
                color: #ffffff;
                margin-left: 5px;
            }

            .hide-nav{
                width: 100%;
                position: absolute;
                top: 100%;
                z-index: 999;

                .child-box {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #ffffff;
                    border-bottom: 1px solid #F6F8FB;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                }

                .child-box:hover {
                    background: #16B82E;
                    color: #ffffff;
                }
            }
        }
    }
}

.banner-box {
  width: 100%;
  height: 667px;
}

.nav-name-box {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 18px;
    justify-content: center;
    align-items: center; 
    position: relative;
}

</style>