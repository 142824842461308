<template>
    <Title></Title>
    <router-view />
    <Bottom></Bottom>
</template>

<script>
import Title from "@/components/Title.vue"
import Bottom from "@/components/Bottom.vue"

export default {
  name: 'APP',
  components: {
    Title,
    Bottom
  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

/* 隐藏垂直滚动条 */
::-webkit-scrollbar {
    display: none; /* 将垂直滚动条隐藏起来 */
}
</style>
