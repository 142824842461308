import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import axios from "axios"
import ElementPlus from "element-plus"
import "element-plus/dist/index.css"
import zhCn from "element-plus/es/locale/lang/zh-cn"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'



const app = createApp(App).use(store).use(router).use(ElementPlus, {locale: zhCn})

// 全局注册 Icon

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.config.globalProperties.axios = axios

app.mount("#app")
