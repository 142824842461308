<template>
  <div class="home">
    <!-- 轮播图 -->
    <div class="banner-box">
      <el-carousel height="100%" v-if="imgList.length" autoplay style="width: 100%; height: 100%;" :pause-on-hover="false"
        indicator-position="none">
        <el-carousel-item style="height: 667px" v-for="(item, index) in imgList" :key="index">
          <img :src="item.imgUrl" style="width: 100%; height: 100%;" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 新闻与知识 -->
    <div class="news-box">
      <!-- 新闻模块 -->
      <div class="news-centre">
        <div class="slideshow-box">
          <el-carousel height="100%" v-if="newsImgs.length" autoplay style="width: 100%; height: 100%;" :interval="5000"
            :pause-on-hover="false" indicator-position="none">
            <el-carousel-item style="height: 100%" v-for="(item, index) in newsImgs" :key="index">
              <img :src="item.viewImg" style="width: 100%; height: 100%;" v-if="item.viewImg.length > 0" />
              <div class="new-img-text">{{ item.title }}</div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="news-data-box">
          <div class="news-title-box" @Click="goNewsPage(1, null, null)">
            <div class="left-title">新闻中心</div>
            <div class="right-load" style="cursor:pointer;">
              <span>更多</span>
              <el-icon>
                <DArrowRight />
              </el-icon>
            </div>
          </div>
          <div class="news-list-box">
            <div class="news-centent-info" v-for="(item, index) in newsList" :key="index"  @Click="goNewsPage(item.type, item.id, item.enteringType)" style="cursor:pointer;">
              <div style="margin-top: 5px;"><el-icon>
                  <CaretRight style="color: #16B82E; font-size: 18px;" />
                </el-icon></div>
              <div class="title-name-box">{{ item.title }}</div>
              <div style="width: 100px;">{{ item.createTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 知识模块 -->
      <div class="knowledge-centre">
        <div class="slideshow-box">
          <img src="../assets/images/知识科普.png" style="width: 100%; height: 100%;" />
          <!-- <el-carousel height="100%" v-if="docList.length" autoplay style="width: 100%; height: 100%;" :interval="5000"
            :pause-on-hover="false" indicator-position="none">
            <el-carousel-item style="height: 100%" v-for="(item, index) in docList" :key="index">
              <img :src="item.viewImg" style="width: 100%; height: 100%;" v-if="item.viewImg.length > 0" />
              <div class="new-img-text">{{ item.title }}</div>
            </el-carousel-item>
          </el-carousel> -->
        </div>
        <div class="news-data-box">
          <div class="news-title-box">
            <div class="left-title">科普知识</div>
            <div class="right-load" style="cursor:pointer;" @click="goKnowsPage(null)">
              <span>更多</span>
              <el-icon>
                <DArrowRight />
              </el-icon>
            </div>
          </div>
          <div class="news-list-box">
            <div class="news-centent-info" v-for="(item, index) in docList" :key="index" style="cursor:pointer;" @click="goKnowsPage(item)">
              <div style="margin-top: 5px;"><el-icon>
                  <CaretRight style="color: #16B82E; font-size: 18px;" />
                </el-icon></div>
              <div class="title-name-box">{{ item.title }}</div>
              <div style="width: 100px;">{{ item.createTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 科普活动 -->
    <div class="activity-box">
      <div class="title-box">
        <div class="left-title">科普活动</div>
        <div class="right-load" @click="toActivityPage(null)">
          <span>更多</span>
          <el-icon>
            <DArrowRight />
          </el-icon>
        </div>
      </div>
      <div class="center-box">
        <div class="img-box" v-for="(item, index) in activeList" :key="index" @click="toActivityPage(item)">
          <img v-if="item.viewImg" :src="item.viewImg" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 9;">
          <img v-else src="../assets/images/空图片.png" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 9;">
          <div class="active-name">{{ item.activityName }}</div>
          <div style=" width:  100%; height: 100%; background: rgba(0, 0, 0, 0.3); position: absolute; top: 0; left: 0; z-index: 10;"></div>
        </div>
      </div>
    </div>
    <!-- 生命馆图片 -->
    <div style="width: auto; height: 167px; margin: 0 50px;" @click="toLookBackPage">
      <img src="../assets/images/生命馆.jpg" style="width: 100%; height: 100%;" />
    </div>
    <!-- 服务团队 -->
    <div class="friendship-link">
      <div class="title-box">
        <div class="left-title">友情链接</div>
      </div>
      <div class="link-list">
        <el-select style="margin-top: 50px; margin-right: 80px;" v-model="item.id" placeholder="请选择" 
          v-for="(item, index) in linkList" :key="index" @change="goToPageUrl(index)">
          <el-option v-for="(val, idx) in item.friendlyLinkList" :key="idx" :label="val.name" :value="val.id">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import api from "@/api/commonApi"

export default {
  name: "Home",
  data() {
    return {
      imgList: [],
      newsList: [],
      newsImgs: [],
      docList: [],
      activeList: [],
      linkList: [],
    }
  },
  created() {
    this.getBannerImgs()
    this.getNewsList()
    this.getDocList()
    this.getActiveList()
    this.getLinkList()
  },
  methods: {
    // 获取轮播图
    getBannerImgs() {
      api.bannerList({ status: 0 }).then(res => {
        if (res) {
          this.imgList = res
        }
      })
    },
    // 获取新闻
    getNewsList() {
      api.newsList({ current: 1, size: 8, status: 1, showState: 0 }).then(res => {
        if (res) {
          this.newsImgs = []
          this.newsList = res.records
          for (let val of res.records) {
            if (val.viewImg) {
              this.newsImgs.push(val)
            }
          }
        }
      })
    },
    // 获取科普知识
    getDocList() {
      api.docList({ current: 1, size: 5, status: 1 }).then(res => {
        if (res) {
          this.docList = res.records
        }
      })
    },
    // 获取活动列表
    getActiveList() {
      api.activeList({current: 1, size: 3, status: 1, viewState: 0, selType: 0}).then(res => {
        if(res) {
          if (res.records.length == 2) {
            api.activeList({current: 1, size: 3, status: 1, viewState: 0, selType: 1}).then(resp => {
              this.activeList = resp.records
            })
          }else {
            this.activeList = res.records
          }
        }
      })
    },
    // 获取友情链接
    getLinkList() {
      api.linkClassList({status: 0, rootIn: 1}).then(res => {
        if (res) {
          this.linkList = []
          for (let val of res){
            val.friendlyLinkList.unshift({id: val.id, name: val.name, status: 1})
            this.linkList.push(val)
          }
        }
      })
    },
    // 友情链接跳转
    goToPageUrl(index) {
      let map = this.linkList[index].friendlyLinkList.find(val => {
        return val.id == this.linkList[index].id
      })
      this.linkList[index].id = this.linkList[index].friendlyLinkList[0].id
      window.open(map.url)
    },
    // 跳转到新闻页
    goNewsPage(type, id, entType) {
      this.$router.push({name: 'News', query: {
          type: type,
          id: id,
          enteringType: entType
      }})
    },
    // 跳转到知识科普页
    goKnowsPage(val) {
      let url = val != null && val.type == 2?"KnowVideo":"Article"
      this.$router.push({name: url, params: val})
    },
    // 科普活动
    toActivityPage(val) {
      if(val != null) {
        this.$router.push({name: 'Activity', query: {
          id: val.id,
          type: val.enterIntoType
        }})
      }else{
        this.$router.push({name: 'Activity', query: {
          type: 0
        }})
      }
      
    },
    // 前往生命科学馆
    toLookBackPage() {
      this.$router.push({name: 'LookBack'})
    },
  }
};
</script>

<style lang="less">
.home {
  margin-top: -10px;
}

.banner-box {
  width: 100%;
  height: 667px;
}

.news-box {
  width: auto;
  margin: 80px 50px;

  .news-centre {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: row;

    .slideshow-box {
      width: 38%;
      height: 100%;
      position: relative;


      .new-img-text {
        width: 100%;
        height: 40px;
        line-height: 40px;
        position: absolute;
        bottom: 0px;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.3);
        overflow: hidden;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
      }
    }

    .news-data-box {
      width: 58%;
      height: 100%;
      margin-left: 4%;

      .news-title-box {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 2px solid #16B82E;

        .left-title {
          width: 220px;
          height: 100%;
          font-size: 24px;
          font-weight: bold;
          color: #ffffff;
          background: #16B82E;
          display: flex;
          justify-content: center;
          align-items: center;
          letter-spacing: 10px;
        }

        .right-load {
          font-size: 18px;
          letter-spacing: 5px;
          display: flex;
          align-items: center;
          margin-top: 20px;
        }
      }


      .news-list-box {
        width: 100%;
        height: calc(100% - 80px);
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .news-centent-info {
          width: 100%;
          height: 50px;
          line-height: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: 1px solid #DAE5F1;

          .title-name-box {
            width: calc(100% - 220px);
            display: flex;
            flex-grow: 1;
            margin: 0 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            text-align: left !important;
          }
        }
      }

    }
  }

  .knowledge-centre {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: row;
    margin-top: 80px;

    .slideshow-box {
      width: 38%;
      height: 100%;
      position: relative;


      .new-img-text {
        width: 100%;
        height: 40px;
        line-height: 40px;
        position: absolute;
        bottom: 0px;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.3);
        overflow: hidden;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
      }
    }

    .news-data-box {
      width: 58%;
      height: 100%;
      margin-left: 4%;

      .news-title-box {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 2px solid #16B82E;

        .left-title {
          width: 220px;
          height: 100%;
          font-size: 24px;
          font-weight: bold;
          color: #ffffff;
          background: #16B82E;
          display: flex;
          justify-content: center;
          align-items: center;
          letter-spacing: 10px;
        }

        .right-load {
          font-size: 18px;
          letter-spacing: 5px;
          display: flex;
          align-items: center;
          margin-top: 20px;
        }
      }


      .news-list-box {
        width: 100%;
        height: calc(100% - 80px);
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .news-centent-info {
          width: 100%;
          height: 50px;
          line-height: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: 1px solid #DAE5F1;

          .title-name-box {
            width: calc(100% - 220px);
            display: flex;
            flex-grow: 1;
            margin: 0 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            text-align: left !important;
          }
        }
      }

    }
  }


}

.activity-box {
  width: auto;
  margin: 100px 50px;

  .title-box {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #D1D9E0;

    .left-title {
      width: 220px;
      height: 100%;
      font-size: 28px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 10px;
    }

    .right-load {
      font-size: 18px;
      letter-spacing: 5px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-right: 20px;
    }
  }

  .center-box {
    width: 100%;
    height: 400px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    .img-box {
      width: 30%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.2);

      .active-name {
        font-size: 28px;
        font-weight: bold;
        color: #ffffff;      
        letter-spacing: 2px;  
        z-index: 99;
      }
    }
  }
}

.friendship-link {
  width: auto;
  margin: 100px 50px;

  .title-box {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #D1D9E0;

    .left-title {
      width: 220px;
      height: 100%;
      font-size: 28px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 10px;
    }

    .right-load {
      font-size: 18px;
      letter-spacing: 5px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-right: 20px;
    }
  }

  .link-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    padding-left: 40px;

  }
}

</style>
